import * as React from 'react'
import { useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import { ComMain } from '@components/index'
import { FeedInFunc } from '@func/index'
import RouteMap from '@svgs/route_map.svg'

const AccessPage = () => {

  useEffect(() => {
    FeedInFunc();
  }, []);

  return (
    <main className='mainAccess'>
      {/* CONTENTS */}

      <ComMain
        ttl="ACCESS"
        subttl="アクセス"
        caption="image photo"
      >
        <StaticImage
          src="../assets/images/access/main.jpg"
          alt=""
          className="_pc"
        />
        <StaticImage
          src="../assets/images/access/main_sp.jpg"
          alt=""
          className="_sp"
        />
      </ComMain>


      <section className='sec-lead'>
        <div className="c-content">

          <div className="lead-wrap">
            <div className="lead-box comFeed">
              <p className="lead">電車もマイカーも、ストレスフリーな<br className='_sp' />交通ネットワーク。</p>
              <div className="main-img">
                <div className="img">
                  <StaticImage
                    src="../assets/images/access/i_1.jpg"
                    alt=""
                  />
                </div>
                <p className="c-cap">航空写真</p>
              </div>

            </div>
          </div>

        </div>
      </section>


      <section className='sec-train'>
        <div className="c-content">

          <div className="train-wrap">
            <div className="lead-box comFeed">
              <p className="ttl">TRAIN ACCESS</p>
              <p className="lead">6路線が使い分けできる多彩なアクセス。<br />新幹線の利用で東京へもスピーディーに。</p>
            </div>

            <div className="gallery-box comFeed">
              <div className="list">
                <div className="img">
                  <StaticImage
                    src="../assets/images/access/i_2.jpg"
                    alt=""
                  />
                </div>
                <div className="ttl-box">
                  <p className="ttl">新横浜</p>
                  <p className="subttl">SHINYOKOHAMA</p>
                </div>
              </div>
              <div className="list">
                <div className="img">
                  <StaticImage
                    src="../assets/images/access/i_3.jpg"
                    alt=""
                  />
                </div>
                <div className="ttl-box">
                  <p className="ttl">品川</p>
                  <p className="subttl">SHINAGAWA</p>
                </div>
              </div>
              <div className="list">
                <div className="img">
                  <StaticImage
                    src="../assets/images/access/i_4.jpg"
                    alt=""
                  />
                </div>
                <div className="ttl-box">
                  <p className="ttl">東京</p>
                  <p className="subttl">TOKYO</p>
                </div>
              </div>
              <div className="list">
                <div className="img">
                  <StaticImage
                    src="../assets/images/access/i_5.jpg"
                    alt=""
                  />
                </div>
                <div className="ttl-box">
                  <p className="ttl">熱海</p>
                  <p className="subttl">ATAMI</p>
                </div>
              </div>
            </div>

            <div className="note-box">
              <p className="note">all image photo</p>
            </div>

            <div className="map-box comFeed">
              <div className="img">
                <RouteMap />
                <p className="note">アクセス図※1</p>
              </div>
            </div>

            <div className="data-box">
              <div className="bullet comFeed">
                <p className="ttl">新幹線利用</p>
                <p className="lead">JR東海道新幹線「小田原」駅より</p>
                <div className="list-box">
                  <div className="list">
                    <p className="p1"><span>｢熱　海｣</span>駅まで</p>
                    <p className="p2">直通<span>7</span>分</p>
                    <p className="p3">（通勤時：7分）</p>
                    <p className="p4"> JR東海道新幹線こだま利用。</p>
                  </div>
                  <div className="list">
                    <p className="p1"><span>｢新横浜｣</span>駅まで</p>
                    <p className="p2">直通<span>14</span>分</p>
                    <p className="p3">（通勤時：15分）</p>
                    <p className="p4">JR東海道新幹線こだま利用。</p>
                  </div>
                  <div className="list">
                    <p className="p1"><span>｢品　川｣</span>駅まで</p>
                    <p className="p2">直通<span>26</span>分</p>
                    <p className="p3">（通勤時：26分）</p>
                    <p className="p4">JR東海道新幹線こだま利用。</p>
                  </div>
                  <div className="list">
                    <p className="p1"><span>｢東　京｣</span>駅まで</p>
                    <p className="p2">直通<span>33</span>分</p>
                    <p className="p3">（通勤時：34分）</p>
                    <p className="p4">JR東海道新幹線こだま利用。</p>
                  </div>
                </div>
              </div>

              <div className="conventional comFeed">
                <p className="ttl">在来線利用</p>
                <p className="lead">JR東海道本線・東海道新幹線、小田急小田原線、箱根登山鉄道、<br />伊豆箱根鉄道大雄山線「小田原」駅より</p>
                <div className="list-box">
                  <div className="list">
                    <p className="p1"><span>｢箱根湯本｣</span>駅まで</p>
                    <p className="p2">直通<span>14</span>分</p>
                    <p className="p3">（通勤時：15分）</p>
                    <p className="p4">箱根登山鉄道利用。</p>
                  </div>
                  <div className="list">
                    <p className="p1"><span>｢熱　海｣</span>駅まで</p>
                    <p className="p2">直通<span>23</span>分</p>
                    <p className="p3">（通勤時：23分）</p>
                    <p className="p4">JR東海道本線利用。</p>
                  </div>
                  <div className="list">
                    <p className="p1"><span>｢沼　津｣</span>駅まで</p>
                    <p className="p2">直通<span>42</span>分</p>
                    <p className="p3">（通勤時：44分）</p>
                    <p className="p4">JR東海道本線利用。(通勤時:JR東海道本線利用。｢熱海｣駅でJR東海道本線に乗換え。)</p>
                  </div>
                  <div className="list">
                    <p className="p1"><span>｢横　浜｣</span>駅まで</p>
                    <p className="p2">直通<span>44</span>分</p>
                    <p className="p3">（通勤時：61分）</p>
                    <p className="p4">JR湘南新宿ライン特別快速利用。(通勤時:JR東海道線(上野東京ライン)利用。)</p>
                  </div>
                  <div className="list">
                    <p className="p1"><span>｢品　川｣</span>駅まで</p>
                    <p className="p2">直通<span>64</span>分</p>
                    <p className="p3">（通勤時：83分）</p>
                    <p className="p4">JR湘南新宿ライン特別快利用。｢大船｣駅でJR東海道本線(上野東京ライン)に乗換え。(通勤時:JR東海道本線(上野東京ライン)利用。)</p>
                  </div>
                  <div className="list">
                    <p className="p1"><span>｢東　京｣</span>駅まで</p>
                    <p className="p2">直通<span>73</span>分</p>
                    <p className="p3">（通勤時：94分）</p>
                    <p className="p4">JR湘南新宿ライン特別快利用。｢大船｣駅でJR東海道本線(上野東京ライン)に乗換え。(通勤時:JR東海道本線(上野東京ライン)利用。)</p>
                  </div>
                  <div className="list">
                    <p className="p1"><span>｢新　宿｣</span>駅まで</p>
                    <p className="p2">直通<span>74</span>分</p>
                    <p className="p3">（通勤時：98分）</p>
                    <p className="p4">小田急小田原線快急行利用。</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>


      <section className='sec-car'>
        <div className="bg-img">
          <div className="img">
            <StaticImage
              src="../assets/images/access/i_7.png"
              alt=""
            />
            <p className="c-cap">image photo</p>
          </div>
        </div>

        <div className="c-content">

          <div className="car-wrap">
            <div className="lead-box comFeed">
              <p className="ttl">CAR ACCESS</p>
              <p className="lead">小田原厚木道路「荻窪IC」へ約5分。<br />レジャーもビジネスも<br className='_sp' />マイカーで気軽に気ままに。</p>
            </div>
            <div className="map-box comFeed">
              <div className="img">
                <StaticImage
                  src="../assets/images/access/i_6.png"
                  alt=""
                />
                <p className="note">カーアクセス図<br />※表示される所要時間は、主として規制速度（高速道路は区間により60～100km/h、都市高速などは時速40～60km/h）にて計算したドラぷらの発表している通常所要時間を掲載しています。（出典：ドラぷら）</p>
              </div>
            </div>
          </div>

          <div className="ic-wrap">
            <div className="ic-box">
              <p className="ic-ttl">小田原厚木道路「荻窪」I.C利用</p>

              <div className="list-box">
                <div className="list">
                  <p className="p1"><span>｢海老名｣</span>IC</p>
                  <p className="p2">車で約<span>32</span>分</p>
                  <p className="p3">（約32.9km）</p>
                </div>
                <div className="list">
                  <p className="p1"><span>｢高尾山｣</span>IC</p>
                  <p className="p2">車で約<span>51</span>分</p>
                  <p className="p3">（約57.8km）</p>
                </div>
                <div className="list">
                  <p className="p1"><span>｢渋　谷｣</span>IC</p>
                  <p className="p2">車で約<span>60</span>分</p>
                  <p className="p3">（約73.5km）</p>
                </div>
                <div className="list">
                  <p className="p1"><span>｢御殿場｣</span>IC</p>
                  <p className="p2">車で約<span>64</span>分</p>
                  <p className="p3">（約78.6km）</p>
                </div>
                <div className="list">
                  <p className="p1"><span>｢霞ヶ関｣</span>IC</p>
                  <p className="p2">車で約<span>66</span>分</p>
                  <p className="p3">（約78.6km）</p>
                </div>
                <div className="list">
                  <p className="p1"><span>｢銀　座｣</span>IC</p>
                  <p className="p2">車で約<span>71</span>分</p>
                  <p className="p3">（約83.1km）</p>
                </div>

              </div>
            </div>
          </div>

        </div>


        <div className="c-content">
          <div className="com-note-wrap">
            <p className="c-note">※箱根湯本／箱根登山鉄道利用で直通14分（通勤時:15分）、熱海／箱根登山鉄道利用で直通23分（通勤時:23分）、横浜／JR湘南新宿ライン特別快速利用で直通44分（通勤時:61分）、品川／JR湘南新宿ライン特別快速利用、「大船」駅でJR東海道線（上野東京ライン）に乗換えで64分（通勤時:83分）、東京／JR湘南新宿ライン特別快速利用、「大船」駅でJR東海道線（上野東京ライン）に乗換えで直通73分（通勤時:54分）、新宿／小田急小田原線快速急行利用で74分（通勤時:98分）。<br />※1掲載の所要時間は通勤時のもので、（）内は日中平常時のものです。乗換え、待ち時間を含んでおります。<br />また、掲載の情報は2022年1月現在のもので、ダイヤ改正等により今後変更となる場合があります。<br />※掲載の環境写真は、2021年10月に撮影したものです。<br />※掲載の距離表示については地図上の概測距離を、徒歩分数表⽰については80m＝1分として算出し、端数を切り上げしたものです。<br />※掲載の写真は、2021年10月撮影の航空写真に⼀部CG処理をしており実際とは多少異なります。また現地を示した光は、建物の高さや規模を表現したものではありません。周辺環境等は今後変更となる場合があり、将来に渡り保証されるものではありません。<br />※車の所要時間は、時速40kmでの走行を想定して算出しています。</p>
          </div>
        </div>


      </section>


      {/* CONTENTS */}
    </main>
  )
}

export default AccessPage
